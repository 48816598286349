import { Container, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import ContactForm from '../common/contact-form';
import { useGlobalState } from '../../logic/state/GlobalStateContext';

const ContactSection: React.FC = () => {
    const { isMobile } = useGlobalState();

    return (  
        <Container maxWidth='lg' sx={{ mt: 20, position: 'relative' }}>
            <Typography variant={isMobile ? 'h3' : 'h1'} gutterBottom sx={{ textAlign: 'center' }}>Contact Us</Typography>
            <ContactForm />
        </Container>
    );
}
 
export default ContactSection;