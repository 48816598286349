export interface GlobalState {
    isMobile: boolean;
    clientData: any[];
    dispatch?: React.Dispatch<GlobalStateAction>;
}

export const InitialState: GlobalState = {
    isMobile: false,
    clientData: []
}

interface ISetIsMobile {
    type: GlobalStateActionTypes.SET_IS_MOBILE;
    payload: boolean;
}

interface ISetClientData {
    type: GlobalStateActionTypes.SET_CLIENT_DATA;
    payload: any[];
}

export enum GlobalStateActionTypes {
    SET_IS_MOBILE = 'SET_IS_MOBILE',
    SET_CLIENT_DATA = 'SET_CLIENT_DATA'
}

export type GlobalStateAction = 
    | ISetIsMobile
    | ISetClientData;

export const GlobalStateReducer = (state: GlobalState, action: GlobalStateAction): GlobalState => {
    switch (action.type) {
        case GlobalStateActionTypes.SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload
            }
        case GlobalStateActionTypes.SET_CLIENT_DATA:
            return {
                ...state,
                clientData: action.payload
            }
        default:
            return state;
    }
}