import React from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarConfigurator } from './components/common/snackbarConfig';
import { SnackbarProvider } from 'notistack';
import Router from './router';
import GlobalStateProvider from './logic/state/GlobalStateContext';
import { themeOptions } from './styles/theme';
import '@fontsource-variable/jetbrains-mono';
import '@fontsource-variable/roboto-flex';
import '@fontsource/pt-sans-narrow';

function App() {
  const theme = createTheme(themeOptions);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <SnackbarConfigurator />
          <BrowserRouter>
            <GlobalStateProvider>
              <Router />
            </GlobalStateProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
