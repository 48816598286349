import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGlobalState } from './logic/state/GlobalStateContext';
import { GlobalStateActionTypes } from './logic/state/GlobalStateReducer';

const Router: React.FC = () => {
    const { dispatch } = useGlobalState();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    React.useEffect(() => {
        dispatch!({ type: GlobalStateActionTypes.SET_IS_MOBILE, payload: isMobile });
    }, [isMobile]);
        
    return (  
        <Routes>
            <Route index element={<Home />} />
        </Routes>
    );
}
 
export default Router;