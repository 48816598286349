import * as React from 'react';
import { GlobalState, GlobalStateReducer, InitialState } from './GlobalStateReducer';

const GlobalStateContext = React.createContext<GlobalState>(InitialState);

const GlobalStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = React.useReducer(GlobalStateReducer, InitialState);

    return (  
        <GlobalStateContext.Provider value={{ ...state, dispatch }}>
            {children}
        </GlobalStateContext.Provider>
    );
}
 
export default GlobalStateProvider;

export const useGlobalState = () => {
    return React.useContext(GlobalStateContext);
}