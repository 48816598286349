import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#95160C',
    },
    secondary: {
      main: '#380C0F',
    },
  },
  typography: {
    fontFamily: 'PT Sans Narrow',
  },
};