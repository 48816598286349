import * as React from 'react';
import Hero from './hero';
import Menu from '../common/menu';
import AboutSection from './about-section';
import PortfolioSection from './portfolio';
import { useGlobalState } from '../../logic/state/GlobalStateContext';
import { GlobalStateActionTypes } from '../../logic/state/GlobalStateReducer';
import { clientData } from './clientinfo';
import ContactSection from './contact-section';
import Footer from '../common/footer';

const Home: React.FC = () => {
    const { dispatch } = useGlobalState();

    React.useEffect(() => {
        dispatch!({ type: GlobalStateActionTypes.SET_CLIENT_DATA, payload: clientData });
    }, []);

    return (  
        <React.Fragment>
            <Menu />
            <Hero />
            <AboutSection />
            <PortfolioSection />
            <ContactSection />
            <Footer />
        </React.Fragment>
    );
}
 
export default Home;