import { Box, Button, TextField } from '@mui/material';
import * as React from 'react';
import CustomButton from './custom-button';
import { IContactFormSubmission } from '../../interfaces/IContactFormSubmission';
import emailService from '../../services/emailService';
import notify from '../common/snackbarConfig';

const ContactForm: React.FC = () => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data: IContactFormSubmission = {
            firstName,
            lastName,
            email,
            phone,
            message
        };

        (async () => {
            await emailService.sendEmail(data).then((response) => {
                if (response) {
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                    notify.success('Email Sent Successfully!');
                } else {
                    notify.error('Failed to send email. Please try again later.');
                }
            })
            .catch((error) => {
                notify.error('Failed to send email. Please try again later.');
            });
        })();
    }

    return (  
        <React.Fragment>
            <form onSubmit={handleSubmit} style={{ margin: '1rem', padding: '1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField 
                        label='First Name' 
                        variant='filled' 
                        fullWidth 
                        required 
                        sx={{ mr: '0.5rem', mb: '1rem' }} 
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField 
                        label='Last Name' 
                        variant='filled' 
                        fullWidth 
                        required 
                        sx={{ ml: '0.5rem', mb: '1rem' }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <TextField 
                        label='Email' 
                        variant='filled' 
                        fullWidth 
                        required 
                        sx={{ mb: '1rem', mr: '0.5rem' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <TextField 
                        label='Phone #' 
                        variant='filled' 
                        fullWidth 
                        sx={{ mb: '1rem', ml: '0.5rem' }}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)} />
                </div>
                <TextField 
                    label='Message' 
                    variant='filled' 
                    required 
                    multiline 
                    rows={4} 
                    fullWidth 
                    sx={{ mb: '1rem' }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)} />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomButton type='submit' variant='contained' color='primary'>Submit</CustomButton>
                </div>
            </form>
        </React.Fragment>
    );
}
 
export default ContactForm;