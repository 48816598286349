import { IContactFormSubmission } from "../interfaces/IContactFormSubmission";
import getHttpService from "./httpService";

const { httpService } = getHttpService();

const sendEmail = async (data: IContactFormSubmission) =>
    await httpService.post('/Contact/ContactForm?domain=oakleydye.com', data).then((response) => response.status === 200 ? true : false);

const emailService = {
    sendEmail
};

export default emailService;